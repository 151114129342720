import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import * as React from "react"
import logo from "./assets/velzion_WText1.png"
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import { useState, useEffect } from "react"

import "./header.css"
// const Responsive = styled("div")(({ theme }) => ({
//     [theme.breakpoints.up("tablet")]: {
//         fontSize: 16,
//         color: "green"
//     },
//     [theme.breakpoints.up("laptop")]: {
//         color: "red",
//         fontSize: 24
//     },
//     [theme.breakpoints.up("lg")]: {
//         fontSize: 48,
//         color: "purple"
//     }
// }))
function Header() {
    const [w, setWidth] = useState()
    const [state, setState] = useState({ right: false, });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItem = (id) => {
        var element = document.getElementById(id);
        var headerElement = document.getElementById('menuHeader');
        var headerOffset = headerElement.getBoundingClientRect().bottom;
        var elementPosition = element.getBoundingClientRect().top;

        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" })
        handleClose();
    }

    
    useEffect(() => {
        const resize = () => {
            setWidth(window.innerHeight)
        }
        window.addEventListener("resize", resize)
        return () => {
            window.removeEventListener("resize", resize)
        }

    }, [])

    return (
        <Box id="menuHeader" sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, height:{mobile:"60px",tablet:"70px",laptop:"70px",desktop:"90px"},display:"flex", flexDirection:"row",alignItems:"center", marginLeft: "auto", marginRight: "auto",justifyContent:"space-between" }}>
            <Box component="img" sx={{ width: {mobile:"130px",tablet:"90px",laptop:"170px",desktop:"170px"}, display: { mobile: 'block', laptop: 'none' }}} src={logo} />
            
            <Box sx={{ flexGrow: 1, display: { mobile: 'none', laptop: 'flex' }, justifyContent:"space-between" }}>
                {/* <Box sx={{width: {mobile:"80px",tablet:"90px",laptop:"90px",desktop:"110px"}, marginTop:{mobile:"15px"} }}> */}
                <Box component="img" sx={{ width: {mobile:"80px",tablet:"90px",laptop:"110px",desktop:"110px"}}} src={logo} />
                {/* </Box> */}
                <Button className="headernav" sx={{color:"#FFFFFF",textTransform:"capitalize",fontSize:{mobile:16,laptop:16,desktop:18}}} onClick={() => handleMenuItem('herobanner')}>Vision</Button>
                <Button className="headernav" sx={{color:"#FFFFFF",textTransform:"capitalize",fontSize:{mobile:16,laptop:16,desktop:18}}} onClick={() => handleMenuItem('services')}>Services</Button>
                <Button className="headernav" sx={{color:"#FFFFFF",textTransform:"capitalize",fontSize:{mobile:16,laptop:16,desktop:18}}} onClick={() => handleMenuItem('aboutUs')}>About Us</Button>
                <Button className="headernav" sx={{color:"#FFFFFF",textTransform:"capitalize",fontSize:{mobile:16,laptop:16,desktop:18}}} onClick={() => handleMenuItem('mission')}>Mission</Button>
                {/* <Button className="headernav" sx={{color:"#FFFFFF",textTransform:"capitalize",fontSize:{mobile:16,laptop:10,desktop:18}}} onClick={() => handleMenuItem('teamMembers')}>Our Team</Button> */}
                <Button className="headernav" sx={{color:"#FFFFFF",textTransform:"capitalize",fontSize:{mobile:16,laptop:16,desktop:18}}} onClick={() => handleMenuItem('contactUs')}>Contact Us</Button>
            </Box>
            <Box sx={{ display: { mobile: 'flex', laptop: 'none' }, alignItems: "flex-end" }}>
                <Box >
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MenuOutlinedIcon sx={{fontSize:{mobile:16,tablet:24,laptop:32},color:"white",border:"1px solid white",borderRadius:1,width: "1.5em",height: "1.5em"}} />
                    </Button>
                    <Menu
                        id="basic-menu" 
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => handleMenuItem('herobanner')}>Vision</MenuItem>
                        <MenuItem onClick={() => handleMenuItem('services')}>Services </MenuItem>
                        <MenuItem onClick={() => handleMenuItem('aboutUs')}>About Us</MenuItem>
                        <MenuItem onClick={() => handleMenuItem('mission')}>Mission</MenuItem>
                        {/* <MenuItem onClick={() => handleMenuItem('teamMembers')}>Our Team</MenuItem> */}
                        <MenuItem onClick={() => handleMenuItem('contactUs')}>Contact Us</MenuItem>
                    </Menu>

                </Box>

            </Box>
        </Box>
    )
}
export default Header