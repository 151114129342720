import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Grid, Typography, Input, InputLabel, FormControl, InputBase, Button, Paper, IconButton, } from "@mui/material"
import { makeStyles } from "@mui/material/styles"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {useState} from "react"
import axios from "axios"
import "./forms.css"
// import styled from "@emotion/styled"
// const useStyles=makeStyles(theme=>({
//     inputbase:{
//         border:"2px solid white"
//     }

// }))

function Messageform() {
    // const classes=useStyles()
    const [attachment,setAttachment]=useState([{name:"Attachment"}])
    const [name,setName]=useState("")
    const [email,setEmail]=useState("")
    const [message,setMessage]=useState("")
    const [subject,setSubject]=useState("")
    const [successMessage, setSuccessMessage] = useState(false)
    const getFile = () => {
        document.getElementById("getFile").click()
      

      
    }
    const handleSubmit=(e)=>{
        e.preventDefault()
        const headerConfig = {
            headers: {
              'Content-Type': 'text/plain'
            }
          };
        // https://mngipxty9h.execute-api.ap-south-1.amazonaws.com/default/velzion-contact
        axios.post('https://mngipxty9h.execute-api.ap-south-1.amazonaws.com/default/velzion-contact/contact-us', {
            "name": name,
            "email": email,
            "subject": subject,
            "message": message 
          },headerConfig)
          .then(function (response) {
            setName("");
            setMessage("");
            setEmail("");
            setSubject("");
            setSuccessMessage(true);
          })
          .catch(function (error) {
            console.log("in error",error);
          });
        console.log("name",name)
        console.log(email)
        console.log(message)
        console.log(subject)
        console.log(attachment)
       
    }
    // const value= document.getElementById("getFile").value
    const handleChange=(e)=>{
        setAttachment(e.target.files)
        for (let i = 0; i < attachment.length; i++) {
            console.log(attachment)
        }

       
    }
  

    return (
        <Grid container sx={{ placeItems: "center", placeContent:"center",  }}>
            <Grid container sx={{ display: "flex",  placeItems: "start", justifyContent: "flex-start",padding: 2 }}>
                <Grid item sx={{ width: 700 }}>
                    <Typography sx={{ color: "white", fontSize: {mobile:14,laptop:24,tablet:16} }}>We're here for you</Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ color: "white", fontSize: {mobile:10,tablet:12,laptop:14}, paddingtop: 5, }}>Send us a message and we'll make sure to get back to you as soon as we can!</Typography>
                </Grid>
                {successMessage ? 
                    <Grid item>
                        <Typography sx={{ color: "green", fontSize: {mobile:10,tablet:12,laptop:14}, paddingtop: 5, }}>Thank you for contacting us. we'll make sure to get back to you as soon as we can!</Typography>
                    </Grid>
                    : null
                }
                
            </Grid>
            <form style={{}}>
                <Grid container sx={{  }}>
                    <InputBase type="text" placeholder='Name' value={name} onChange={(e)=>setName(e.target.value)}sx={{ width: "inherit", height: 40, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} />
                    <InputBase type="text" placeholder='Email Address' value={email} onChange={(e)=>setEmail(e.target.value)} sx={{ width:"inherit", height: 40, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} />
                    <InputBase type="text" placeholder='Subject' value={subject} onChange={(e)=>setSubject(e.target.value)} sx={{ width:"inherit", height: 40, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} />
                    <InputBase type="text" placeholder='Text Your Message ' value={message} onChange={(e)=>setMessage(e.target.value)} sx={{ width:"inherit", height: 120, color: 'grey', border: "1px white solid", bgcolor: "white", paddingLeft: 2, borderRadius: 2, margin: "10px 16px" }} multiline  rows={4}/>
                </Grid>
                <Grid container sx={{ padding: "0px 0px 24px 16px",marginTop:"10px" }}>
                    <Button  variant="contained" className='submitBtn'  sx={{bgcolor:"#3a824a", width:"132px"}} onClick={handleSubmit}>Submit</Button>
                </Grid>
            </form>
        </Grid>
    )
}
export default Messageform