import Header from "./header"
import {  ThemeProvider, Typography, Grid, Box, Card, CardMedia, CardContent, Button, InputBase, } from "@mui/material"
import { theme } from "./styles"
import "./homepage.css"
import CopyrightIcon from '@mui/icons-material/Copyright';

import { motion, useInView, useAnimation, AnimatePresence } from "framer-motion";

import heroBanner from "./assets/herobanneranime3.gif"
import logo from "./assets/velzion_WText1.png"
import velzion_logo_small from "./assets/velzion_logo1.png"
import velzion_logo from "./assets/velLogoText.png"

import SDoutlined from "./assets/services/SDoutlined.png"
import SDcoloured from "./assets/services/SDcoloured.png"

import PEoutlined from "./assets/services/PEoutlined.png"
import PEcoloured from "./assets/services/PEcoloured.png"

import ITCoutlined from "./assets/services/ITCoutlined.png"
import ITCcoloured from "./assets/services/ITCcoloured.png"

import DAoutlined from "./assets/services/DAoutlined.png"
import DAcoloured from "./assets/services/DAcoloured.png"

import CCoutlined from "./assets/services/CCoutlined.png"
import CCcoloured from "./assets/services/CCcoloured.png"

import aboutusBg from "./assets/aboutus/aboutus.png"

import missionBg from "./assets/mission/mission.png"
import leadershipBg from "./assets/mission/leadership1.png"
import agileBg from "./assets/mission/agile1.png"
import deliveryBg from "./assets/mission/delivery.png"
import developmentBg from "./assets/mission/development.png"

import Messageform from "./forms"
import linkedin_footer from "./assets/Linkedin_footer.svg";

const pov_ani = {
    initial: { opacity:0, scale:1 },
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            // type: "spring",
            // bounce: 1,
            // duration: 3
        // type: "ease-in",
        ease: "easeOut",
        delay: 0.5,
        duration: 2,
        },
    },
};

const title_ani = {
    initial: {opacity: 0, y: 100},
    animate: {
        opacity:1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 1
        }
    }
};

function Home() {
    return(
        <>
        <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: "#151515" }}>
                <Grid className="sticky">
                    <Header />
                </Grid>
                <Box id="herobanner">
                    <Grid container sx={{}} >
                        <Grid item mobile={12}>
                            <Box className="heroBanner">
                                <Box component="img" sx={{width:"100%"}} src={heroBanner}/>
                                <Grid container className="HeroBannerContent" sx={{top: {mobile:"50%",laptop:"40%"}}} >
                                    <Grid item mobile={6}>
                                    <motion.div initial='initial' whileInView="animate" variants={pov_ani}>
                                        <Box sx={{ padding: "0px 22px", marginLeft: "auto", marginRight: "auto"}}>
                                            <Box sx={{ placeContent: "center",textAlign:"-webkit-center", width:"100%"}}>
                                                <motion.div
                                                    whileHover={{ scale: 1.05 }}
                                                    whileTap={{ scale: 1.05 }}
                                                    transition={{ duration: 1 }}
                                                >
                                                    <Box className="content vp_1" sx={{ transform: {mobile:"translateY(-15%)",laptop:"translateY(5%)",desktop:"translateY(0%)"}, minWidth: "-webkit-fill-available",width:{mobile:"120px",tablet:"180px",laptop:"280px"}, height:{mobile:"170px",tablet:"270px",laptop:"430px",desktop:"520px"}, margin:{mobile:"0px",tablet:"15px",laptop:"15px"} }}>
                                                        <Box className="velzBox" sx={{width:{mobile:"120px",tablet:"250px",laptop:"280px",desktop:"350px"}}}>
                                                            <Box component="img" sx={{width:{mobile:"120px",tablet:"180px",laptop:"280px",desktop:"350px"}}} src={velzion_logo} />
                                                        </Box>
                                                        <Box className="content_hover" sx={{width:{mobile:"120px",tablet:"250px",laptop:"400px",desktop:"520px"},transform: {mobile:"translateY(20%)", tablet:"translateY(10%)", laptop:"translateY(25%)",desktop:"translateY(100%)"}}}>
                                                            <Typography sx={{ fontSize: {mobile: 22,tablet:40,laptop:48},fontWeight:700, color: "white", lineHeight:"unset" }}>Unleashing</Typography>
                                                            <Typography sx={{ fontSize: {mobile: 22,tablet:40,laptop:48},fontWeight:700, color: "#04eb6c", lineHeight:"unset" }}>Creativity<span style={{color:"white"}}> through</span></Typography>
                                                            <Typography sx={{ fontSize: {mobile: 22,tablet:40,laptop:48},fontWeight:700, color: "white", lineHeight:"unset" }}><span style={{color:"#ff8900"}}>Intelligent solutions.</span></Typography>
                                                            {/* <Typography sx={{ fontSize: 16, color: "white", padding: 0.5 }}>Building the future of digital innovation.</Typography> */}
                                                        </Box>
                                                    </Box>
                                                </motion.div>
                                            </Box>
                                        </Box>
                                    </motion.div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box id="services" className="servicesBox">
                    <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                        <Box sx={{ display: "flex", justifyContent: "center",paddingTop:"5%" }}>
                            <Typography className="s_gradient" sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #bfff09" }}>What We Do?</Typography>
                        </Box>
                    </motion.div>
                    <motion.div initial='initial' whileInView="animate" variants={pov_ani}>
                        <Grid container sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},display:"flex", flexDirection:"row",alignItems:"center", marginLeft: "auto", marginRight: "auto" }}>
                            <Grid container justifyContent="center" sx={{marginTop:"5%"}} spacing={2}>
                                <Grid item mobile={12} tablet={6} laptop={4}>
                                    <Box className="pes" sx={{bgcolor:"rgb(3 21 38)",margin:"auto",width:"280px",height:{mobile:"auto",tablet:"280px",laptop:"280px"},padding:"3%"}}>
                                        <Box sx={{display:"flex"}}>
                                            <Box component="img" sx={{width:"100px",height:"100px"}} src={PEcoloured}/>
                                            <Box sx={{margin:"auto"}}>
                                                <Typography className="pe_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Production</Typography>
                                                <Typography className="pe_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Excellence</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                            <ul className="Ulist">
                                                <li>Takes care of complete infrastructure support required for the application.</li>
                                                <li>Troubleshooting of issues in production and providing solutions to the end customers.</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item mobile={12} tablet={6} laptop={4}>
                                    <Box className="sds" sx={{bgcolor:"rgb(3 21 38)",margin:"auto",width:"280px",height:{mobile:"auto",tablet:"280px",laptop:"280px"},padding:"3%"}}>
                                        <Box sx={{display:"flex"}}>
                                            <Box component="img" className="sds" sx={{width:"100px",height:"100px"}} src={SDcoloured}/>
                                            <Box sx={{margin:"auto"}}>
                                                <Typography className="sd_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Software</Typography>
                                                <Typography className="sd_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Development</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                            <ul className="Ulist">
                                                <li> Building customized software solutions for businesses, using programming languages like Python, Java, and C++.</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item mobile={12} tablet={6} laptop={4}>
                                    <Box className="ccs" sx={{bgcolor:"rgb(3 21 38)",margin:"auto",width:"280px",height:{mobile:"auto",laptop:"280px"},padding:"3%"}}>
                                        <Box sx={{display:"flex"}}>
                                            <Box component="img" className="ccs" sx={{width:"100px",height:"100px"}} src={CCcoloured}/>
                                            <Box sx={{margin:"auto"}}>
                                                <Typography className="cc_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Cloud</Typography>
                                                <Typography className="cc_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Computing</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                            <ul className="Ulist">
                                                <li>Providing cloud-based solutions for businesses, including cloud storage, cloud hosting, and cloud backup services.</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container sx={{marginTop:"5%"}} justifyContent="center" spacing={2}>
                                <Grid item mobile={12} tablet={6} laptop={4}>
                                    <Box className="itcs" sx={{bgcolor:"rgb(3 21 38)",margin:"auto",width:"280px",height:{mobile:"auto",laptop:"220px"},padding:"3%"}}>
                                        <Box sx={{display:"flex"}}>
                                            <Box component="img" className="itcs" sx={{width:"100px",height:"100px"}} src={ITCcoloured}/>
                                            <Box sx={{margin:"auto"}}>
                                                <Typography className="itc_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>IT</Typography>
                                                <Typography className="itc_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Consulting</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                            <ul className="Ulist">
                                                <li> Collecting and analysing data for businesses to gain insights and make informed decisions.</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item mobile={12} tablet={6} laptop={4}>
                                    <Box className="das" sx={{bgcolor:"rgb(3 21 38)",margin:"auto",width:"280px",height:{mobile:"auto",laptop:"220px"},padding:"3%"}}>
                                        <Box sx={{display:"flex"}}>
                                            <Box component="img" className="das" sx={{width:"100px",height:"100px"}} src={DAcoloured}/>
                                            <Box sx={{margin:"auto"}}>
                                                <Typography className="da_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Data</Typography>
                                                <Typography className="da_gradient" sx={{ fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:0,lineHeight:1, fontWeight: "bold", textAlign:"center" }}>Analytics</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ color: "#EFEFEF", lineHeight: 2, }} sx={{fontSize: { mobile: 14, tablet: 14, laptop: 14 } }}>
                                            <ul className="Ulist">
                                                <li> Collecting and analysing data for businesses to gain insights and make informed decisions.</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </motion.div>
                </Box>
                <Box id="aboutUs" className="aboutusBox">
                    <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                        <Box sx={{ display: "flex", justifyContent: "center",paddingTop:"5%" }}>
                            <Typography className="s_gradient" sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #bfff09" }}>Who We Are?</Typography>
                        </Box>
                    </motion.div>
                    <motion.div initial='initial' whileInView="animate" variants={pov_ani}>
                        <Grid container direction="column-reverse" spacing={2} sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},display:"flex", flexDirection:{mobile:"columnReverse",laptop:"row"},alignItems:"center",paddingTop:"5%", marginLeft: "auto", marginRight: "auto" }}>
                            <Grid item mobile={12} tablet={6} laptop={6} >
                                <Box sx={{display:{mobile:"block",laptop:"none"},fontSize: { mobile: 20, tablet: 14, laptop: 14 }
                                        ,color:"rgb(239, 239, 239)",lineHeight: "2",letterSpacing: "1px",width: {mobile:"380px",tablet:"auto"} }}>
                                    <ul className="Ulist classicaFont" style={{padding:"5%",lineHeight:"2"}}>
                                        <li><span className="velzionFontBigMobile"> Velzion</span> is founded to revolutionizing and empowering the future of IT industry with purpose and impact.</li>
                                        {/* <li>In the future of IT world we will be a part of creating new technologies and supporting the industry.</li> */}
                                        <li>We will support application across multiple technologies.</li>
                                        <li>We always stands to be unique in the business organization.</li>
                                        <li>When a project is proposed , we will take care of it and deliver to the client at the fullest dedication.</li>
                                        <li>We always tries to find a unique solution.</li>
                                    </ul>
                                </Box>
                                <Typography className="aboutusContent classicaFont" sx={{display:{mobile:"none",laptop:"block"}, color:"white",fontSize: { mobile: 26, tablet: 26, laptop: 26 },letterSpacing:1, fontWeight: "400px" }}>
                                    <span className="velzionFontBig">Velzion</span> is founded to revolutionizing and empowering the future of IT industry with purpose and impact.  
                                    We will support application across multiple technologies. We always stands to be unique in the business
                                    organization. When a project is proposed , we will take care of it and deliver to
                                    the client at the fullest dedication. We always tries to find a unique solution .
                                </Typography>
                            </Grid>
                            <Grid item mobile={12} tablet={6} laptop={6} sx={{display:"flex", justifyContent:"center"}} >
                                <Box component="img" sx={{width:{mobile:"330px",tablet:"400px"}}} src={aboutusBg}/>
                            </Grid>
                        </Grid>
                    </motion.div>
                </Box>
                <Box id="mission" className="missionBox">
                    <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                        <Box sx={{ display: "flex", justifyContent: "center",paddingTop:"5%" }}>
                            <Typography className="s_gradient" sx={{ fontSize: { mobile: 28, tablet: 40, laptop: 48 },letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #bfff09" }}>Our Mission</Typography>
                        </Box>
                    </motion.div>
                    <motion.div initial='initial' whileInView="animate" variants={pov_ani}>
                    <Grid container sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},display:"flex",justifyContent:"center", flexDirection:"row",alignItems:"center",paddingTop:"5%", marginLeft: "auto", marginRight: "auto" }}>
                        <Box component="img" sx={{width:{mobile:"150px",tablet:"150px"}}} src={leadershipBg}/>
                        <Box sx={{marginLeft:"2%",height:{mobile:"150px",tablet:"150px"},width:{mobile:"5px",tablet:"5px"},borderLeft: "2px solid #bfff09"}}></Box>
                        <Typography className="aboutusContent" sx={{width:{mobile:"180px",tablet:"350px",laptop:"650px"},paddingLeft:"3%", color:"white",fontSize: { mobile: 16, tablet: 22, laptop: 30 },letterSpacing:-1, fontWeight: {mobile:"400",laptop:"bold"} }}>
                            A vision to become a leader in the industry, setting the standard for excellence in technology innovation and customer satisfaction.
                        </Typography>
                    </Grid>
                    <Grid container sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},display:"flex",justifyContent:"center", flexDirection:"row",alignItems:"center",paddingTop:"5%", marginLeft: "auto", marginRight: "auto" }}>
                        <Box component="img" sx={{width:{mobile:"150px",tablet:"150px"}}} src={agileBg}/>
                        <Box sx={{marginLeft:"2%",height:{mobile:"150px",tablet:"150px"},width:{mobile:"5px",tablet:"5px"},borderLeft: "2px solid #bfff09"}}></Box>
                        <Typography className="aboutusContent" sx={{width:{mobile:"180px",tablet:"350px",laptop:"650px"},paddingLeft:"3%", color:"white",fontSize: { mobile: 16, tablet: 22, laptop: 30 },letterSpacing:-1, fontWeight: {mobile:"400",laptop:"bold"} }}>
                            A commitment to being agile and adaptable, able to pivot quickly to respond to changing market conditions and customer needs.
                        </Typography>
                    </Grid>
                    <Grid container sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},display:"flex",justifyContent:"center", flexDirection:"row",alignItems:"center",paddingTop:"5%", marginLeft: "auto", marginRight: "auto" }}>
                        <Box component="img" sx={{width:{mobile:"150px",tablet:"150px"}}} src={deliveryBg}/>
                        <Box sx={{marginLeft:"2%",height:{mobile:"150px",tablet:"150px"},width:{mobile:"5px",tablet:"5px"},borderLeft: "2px solid #bfff09"}}></Box>
                        <Typography className="aboutusContent" sx={{width:{mobile:"180px",tablet:"350px",laptop:"650px"},paddingLeft:"3%", color:"white",fontSize: { mobile: 16, tablet: 22, laptop: 30 },letterSpacing:-1, fontWeight: {mobile:"400",laptop:"bold"} }}>
                            A focus on developing solutions that can scale rapidly to meet the needs of customers as they grow and expand.
                        </Typography>
                    </Grid>
                    <Grid container sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},display:"flex",justifyContent:"center", flexDirection:"row",alignItems:"center",paddingTop:"5%", marginLeft: "auto", marginRight: "auto" }}>
                        <Box component="img" sx={{width:{mobile:"150px",tablet:"150px"}}} src={developmentBg}/>
                        <Box sx={{marginLeft:"2%",height:{mobile:"150px",tablet:"150px"},width:{mobile:"5px",tablet:"5px"},borderLeft: "2px solid #bfff09"}}></Box>
                        <Typography className="aboutusContent" sx={{width:{mobile:"180px",tablet:"350px",laptop:"650px"},paddingLeft:"3%", color:"white",fontSize: { mobile: 16, tablet: 22, laptop: 30 },letterSpacing:-1, fontWeight: {mobile:"400",laptop:"bold"} }}>
                            A focus on delivering solutions that are tailored to the needs of customers, providing an exceptional user experience and delivering measurable value.
                        </Typography>
                    </Grid>
                    </motion.div>
                </Box>
                <Grid id="contactUs" className="contactusBox" sx={{padding: "80px 22px" }}>
                    <Box sx={{maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, marginLeft:"auto", marginRight: "auto"}}>
                        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid item mobile={12} tablet={6} laptop={6} sx={{paddingRight:{laptop:5}}}>
                            <motion.div initial='initial' whileInView="animate" variants={title_ani}>
                                <Box sx={{ display: "flex", justifyContent:{mobile:"center",tablet:"initial"} }}>
                                    {/* <Typography className="gradient" sx={{ fontSize: { mobile: 24, tablet: 30, laptop: 36 }, fontWeight: "bold", textDecoration: "2px", textAlign: { mobile: "center", tablet: "center", laptop: "left" } }}>Contact Us</Typography> */}
                                    <Typography className="s_gradient" sx={{ fontSize: { mobile: 24, tablet: 30, laptop: 36 }, textAlign: {mobile:"center",tablet:"initial"}, letterSpacing:-1, fontWeight: "bold", borderBottom: "2px solid #4B4B4B" }}>Contact Us</Typography>

                                    {/* <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 24 }, color: "#E6E8EB", letterSpacing: { mobile: 2, tablet: 5, laptop: 10 } }}>OUR UNIQUE</Typography> */}
                                </Box>
                                <Box sx={{ display: "flex", justifyContent:{mobile:"center",tablet:"initial"}, paddingTop: "24px" }}>
                                    <Typography sx={{ fontSize: { mobile: 16, tablet: 20, laptop: 22 }, textAlign: {mobile:"center", tablet: "initial"}, color: "#EFEFEF" }}>Your Feedback Drives Us Forward: Reach Out and Let Your Voice Be Heard</Typography>
                                </Box>
                            </motion.div>
                            </Grid>
                            <Grid item mobile={12} tablet={6} laptop={6}
                                sx={{bgcolor: "#05352c", borderRadius: 4,marginTop: 3}} >
                                <Messageform />
                            </Grid>                           
                        </Grid>
                    </Box>
                </Grid>
                <Grid sx={{ paddingTop: {mobile:5,tablet:10},background:"#111111", paddingBottom: 3 }}>
                        <Box id="menuHeader" sx={{ maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"}, padding:"0px 22px", display:"flex",flexFlow: {mobile:"column-reverse",tablet:"nowrap"}, flexDirection:"row", marginLeft: "auto", marginRight: "auto",justifyContent:"center",marginBottom:3 }}>
                            {/* <Grid container>
                                <Grid item mobile={4} tablet={4}> */}
                                    <Box sx={{marginTop:{mobile:3,tablet:0},display:"flex",flexDirection:"column", justifyContent:"center"}}>
                                        <Box component="img" sx={{ margin:"auto",width: {mobile:"80px",tablet:"90px",laptop:"90px",desktop:"110px"} }} src={logo} />
                                        <Typography sx={{margin:"auto", color: "#FFFFFF", fontWeight: "400", fontSize: { mobile: "10px", tablet: "12px", laptop: "12px", }, display: "flex", alignItems: "center", textDecoration: "none" }} ><CopyrightIcon sx={{ fontSize: { mobile: "10px", tablet: "12px", laptop: "12px", }, marginRight: 1 }} /> 2023 Velzion Technologies Inc-All Rights Reserved </Typography>
                                        {/* <Box sx={{marginTop: 1}}> */}
                                            {/* <Box component="img" sx={{ margin: "8px 8px 8px 0px" }} src={Facebook_footer} /> */}
                                            {/* <Box component="img" sx={{ margin: 1 }} src={twitter} /> */}
                                            {/* <a href="" style={{cursor:"pointer"}} target="_blank">
                                                <Box component="img" src={linkedin_footer} sx={{width:20}} />
                                            </a> */}
                                            {/* <Box component="img" sx={{ margin: 1 }} src={Youtube} /> */}
                                        {/* </Box> */}
                                    </Box>
                                {/* </Grid>
                                <Grid item mobile={8} tablet={8}> */}
                                    {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                        <Box>
                                            <Box>
                                                <Typography sx={{ color: "white", fontWeight: "bold", fontSize: { mobile: 12, tablet: 14, laptop: 14 }, paddingBottom: 2 }}>News Letter</Typography></Box>
                                            <Box sx={{ display: "flex", paddingBottom: 2 }}>
                                                <Box component="img" src={mail} />
                                                <Typography sx={{ color: "white", fontSize: { mobile: 12, tablet: 16, laptop: 14, paddingLeft: 14, } }}>Stay up to date on the latest from Arrivu</Typography>
                                            </Box>
                                            <form>
                                                <InputBase type="text" placeholder='Enter Your Email' onChange={(e) => setSubscribe(e.target.value)} id='getFile' sx={{ width: { mobile: 200, tablet: 150, laptop: 250 }, height: 40, color: "white", border: "1px white solid", paddingLeft: 2, borderRadius: .5, fontSize: { mobile: 10, tablet: 14, laptop: 16 }, marginBottom: 2 }} multiline />
                                                <Box>
                                                    <Button style={{}} variant="contained" sx={{ bgcolor: "#0057B8",fontSize: { mobile: 12, tablet: 14, laptop: 14 }, paddingBottom: 1 }} onClick={handleSubmit}>Subscribe</Button>
                                                </Box>
                                            </form>
                                        </Box>

                                    </Box> */}
                                {/* </Grid>
                            </Grid> */}
                        </Box>
                        {/* <Box sx={{maxWidth:{mobile:"980px",desktop:"1100px",desktopLg:"1400px"},padding:"0px 22px", marginLeft: "auto", marginRight: "auto"}}>
                            <Typography sx={{ color: "#FFFFFF", fontWeight: "400", fontSize: { mobile: "10px", tablet: "12px", laptop: "12px", }, display: "flex", alignItems: "center", textDecoration: "none" }} ><CopyrightIcon sx={{ fontSize: { mobile: "10px", tablet: "12px", laptop: "12px", }, marginRight: 1 }} /> 2023 Velzion Technologies Inc-All Rights Reserved </Typography>
                        </Box> */}
                    </Grid> 
            </Box>
        </ThemeProvider>
            {/* <h1>
                Home page
            </h1> */}
        </>
    )
}
export default Home